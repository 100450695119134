import React, { createContext } from 'react'
import useSignalrPrivate from 'src/signalr/useSignalrPrivate'

export const SignalRPrivateContext = createContext()

export const SignalRPrivateProvider = ({ children }) => {
  const { signalWallet, userLogout } = useSignalrPrivate()
  return (
    <SignalRPrivateContext.Provider value={{ signalWallet, userLogout }}>
      {children}
    </SignalRPrivateContext.Provider>
  )
}
