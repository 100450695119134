import React, { Suspense } from 'react'
import { useRoutes } from 'react-router-dom'
import './scss/style.scss'
import ToastContainerComponent from './components/toast/ToastContainerComponent'
import { AgentRoutes, GuarantorRoutes, OperatorsRoutes, defaultRoutes } from './routes/routes'
import { useAuth } from 'src/hooks/auth/useAuth'

const Loading = () => (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

const selectRole = (userId) => {
  switch (userId) {
    case 10:
      return {
        routes: GuarantorRoutes,
      }

    case 7:
      return {
        routes: OperatorsRoutes,
      }
    case 8:
      return {
        routes: AgentRoutes,
      }
    default:
      return {
        routes: defaultRoutes,
      }
  }
}

const App = () => {
  const { token } = useAuth()
  const { routes, background } = selectRole(token?.userType)
  const routesResult = useRoutes(routes)
  return (
    <>
      <div className="App">
        <Suspense fallback={<Loading />}>{routesResult}</Suspense>
        <ToastContainerComponent />
      </div>
    </>
  )
}

export default App
