import React from 'react'
import { Navigate } from 'react-router-dom'
import { ProtectedRoute } from '../components/protected-route/ProtectedRoute'

const GuarantorDashboard = React.lazy(() => import('src/views/dashboard/GuarantorDashboard'))
const MasterAgentDashboard = React.lazy(() => import('src/views/dashboard/MasterAgentDashboard'))
const AgentDashboard = React.lazy(() => import('src/views/dashboard/AgentDashboard'))
const Profile = React.lazy(() => import('src/views/profile/Profile'))
const EditProfile = React.lazy(() => import('src/views/profile/EditProfile'))
const ChangePassword = React.lazy(() => import('src/views/profile/ChangePassword'))
const TransacGuarantorReports = React.lazy(() =>
  import('src/views/reports/TransactionGuarantorReport'),
)

const VIPReports = React.lazy(() => import('src/views/downlinereports/VIPreports/VipReportsList'))
const AgentReports = React.lazy(() =>
  import('src/views/downlinereports/agentreports/AgentListReports'),
)

const OperatorsListReports = React.lazy(() =>
  import('src/views/downlinereports/operatorreports/OperatorsListReports'),
)

const ActivityReportsOperatorList = React.lazy(() =>
  import('src/views/downlinereports/activityreports/ActivityReportsOperatorList'),
)
const ActivityLogTabs = React.lazy(() =>
  import('src/views/downlinereports/activityreports/ActivityLogTabs'),
)
const ActivityLogs = React.lazy(() =>
  import('src/views/downlinereports/activityreports/ActivityLogs'),
)
const OperatorsPerEventReport = React.lazy(() =>
  import('src/views/eventreports/guarantor/OperatorsPerEventReport'),
)
const DownlinePerEventReport = React.lazy(() =>
  import('src/views/eventreports/operator/DownlinePerEventReport'),
)
const OperatorReportTabs = React.lazy(() =>
  import('src/views/downlinereports/reports/OperatorTabs'),
)
const OperatorReportTabsAgent = React.lazy(() =>
  import('src/views/downlinereports/reports/AgentUnderOperatorTabs'),
)
const OperatorReportTabsQR = React.lazy(() =>
  import('src/views/downlinereports/reports/OperatorTabsQR'),
)
const GuarantorReportTabs = React.lazy(() =>
  import('src/views/downlinereports/reports/GuarantorTabs'),
)
const MyRequestDetails = React.lazy(() => import('src/views/reports/WalletReports'))
const Request = React.lazy(() => import('src/views/request/Request'))
const MyRequest = React.lazy(() => import('src/views/request/MyRequest'))
const RequestDownline = React.lazy(() => import('src/views/request/RequestDownline'))
const RequestApproval = React.lazy(() => import('src/views/request/RequestApproval'))
const GuarantorCommissionManagement = React.lazy(() =>
  import('src/views/commission/GuarantorCommissionManagement'),
)
const OperatorCommissionManagement = React.lazy(() =>
  import('src/views/commission/OperatorCommisionManagement'),
)
const AgentCommission = React.lazy(() => import('src/components/commission/AgentUnderMACommission'))
const AgentCommissionManagement = React.lazy(() =>
  import('src/views/commission/AgentCommissionManagement'),
)
//pages
const PendingUsers = React.lazy(() => import('src/views/mydownlines/PendingDownlines'))
const MyOperators = React.lazy(() => import('src/views/mydownlines/MasterAgents'))
const Players = React.lazy(() => import('src/views/mydownlines/Players'))
const Agents = React.lazy(() => import('src/views/mydownlines/Agents'))
const Login = React.lazy(() => import('src/views/login/Login'))
const Register = React.lazy(() => import('src/views/register/RegistrationPage'))
const RegisterAgent = React.lazy(() => import('src/views/register/RegistrationPageAgent'))

const RegisterPlayer = React.lazy(() => import('src/views/register/RegistrationPagePlayer'))
const AuthLayout = React.lazy(() => import('src/components/layout/AuthLayout'))
const MainLayout = React.lazy(() => import('src/components/layout/MainLayout'))

export const GuarantorRoutes = [
  {
    element: <MainLayout />,
    children: [
      {
        path: '/',
        element: <Login />,
      },
    ],
  },
  {
    element: (
      <ProtectedRoute>
        <AuthLayout />
      </ProtectedRoute>
    ),
    children: [
      { path: '/', exact: true, name: 'Home' },
      { path: '/dashboard', name: 'Dashboard', element: <GuarantorDashboard /> },
      { path: '/profile', name: 'Profile', element: <Profile /> },
      { path: '/edit-profile', name: 'Edit Profile', element: <EditProfile /> },
      { path: '/change-password', name: 'Change Password', element: <ChangePassword /> },
      { path: '/master-agents', name: 'Downlines Management', element: <MyOperators /> },
      { path: '/reports', name: 'Transaction Reports', element: <TransacGuarantorReports /> },
      { path: '/masteragent-list', name: 'Operator List', element: <OperatorsListReports /> },
      {
        path: '/activity-reports',
        name: 'Activity User List',
        element: <ActivityReportsOperatorList />,
      },
      { path: '/activitylogs', name: 'Activity Reports', element: <ActivityLogs /> },
      {
        path: '/operators-per-event-commission',
        name: 'Events Reports',
        element: <OperatorsPerEventReport />,
      },

      {
        path: '/operator-reports',
        name: 'Transaction Reports',
        element: <GuarantorReportTabs />,
      },
      {
        path: '/pending-masteragents',
        name: 'Pending Users',
        element: <PendingUsers />,
      },
      // {
      //   path: '/manage-commission',
      //   name: 'Manage Commission',
      //   element: <GuarantorCommissionManagement />,
      // },
      // { path: '/my-request-details', name: 'My Request Details', element: <MyRequestDetails /> },
      // { path: '/request', name: 'Request', element: <Request /> },
      // { path: '/my-request', name: 'My Request', element: <MyRequest /> },
      // { path: '/request-downline', name: 'My Request Downline', element: <RequestDownline /> },
      // { path: '/request-downline', name: 'My Request Downline', element: <RequestDownline /> },
      // { path: '/request-approval', name: 'My Request Approval', element: <RequestApproval /> },
    ],
  },
  {
    path: '*',
    element: <Navigate to="/dashboard" />,
  },
]

export const OperatorsRoutes = [
  {
    element: <MainLayout />,
    children: [
      {
        path: '/',
        element: <Login />,
      },
    ],
  },
  {
    element: (
      <ProtectedRoute>
        <AuthLayout />
      </ProtectedRoute>
    ),
    children: [
      { path: '/', exact: true, name: 'Home' },
      { path: '/dashboard', name: 'Dashboard', element: <MasterAgentDashboard /> },
      { path: '/profile', name: 'Profile', element: <Profile /> },
      { path: '/edit-profile', name: 'Edit Profile', element: <EditProfile /> },
      { path: '/change-password', name: 'Change Password', element: <ChangePassword /> },
      { path: '/players', name: 'Players', element: <Players /> },
      { path: '/agents', name: 'Agents', element: <Agents /> },
      { path: '/reports', name: 'Transaction Reports', element: <TransacGuarantorReports /> },
      { path: '/agent-reports', name: 'Staff List', element: <AgentReports /> },
      { path: '/player-reports', name: 'VIP Reports', element: <VIPReports /> },
      { path: '/activitylogs', name: 'Activity Reports', element: <ActivityLogs /> },
      {
        path: '/downline-per-event-commission',
        name: 'Events Reports',
        element: <DownlinePerEventReport />,
      },
      {
        path: '/operators-per-event-commission',
        name: 'Events Reports',
        element: <OperatorsPerEventReport />,
      },
      {
        path: '/player-records',
        name: 'Transaction Reports',
        element: <OperatorReportTabs />,
      },
      {
        path: '/agent-records',
        name: 'Transaction Reports',
        element: <OperatorReportTabsAgent />,
      },
      {
        path: '/pending-agents',
        name: 'Pending Users',
        element: <PendingUsers />,
      },
      {
        path: '/pending-players',
        name: 'Pending Users',
        element: <PendingUsers />,
      },

      // {
      //   path: '/manage-commission',
      //   name: 'Manage Commission',
      //   element: <OperatorCommissionManagement />,
      // },
      // { path: '/my-request-details', name: 'My Request Details', element: <MyRequestDetails /> },
      // { path: '/request', name: 'Request', element: <Request /> },
      // { path: '/my-request', name: 'My Request', element: <MyRequest /> },
      // { path: '/request-downline', name: 'My Request Downline', element: <RequestDownline /> },
      // { path: '/request-downline', name: 'My Request Downline', element: <RequestDownline /> },
      // { path: '/request-approval', name: 'My Request Approval', element: <RequestApproval /> },
    ],
  },
  {
    path: '*',
    element: <Navigate to="/dashboard" />,
  },
]

export const AgentRoutes = [
  {
    element: <MainLayout />,
    children: [
      {
        path: '/',
        element: <Login />,
      },
    ],
  },
  {
    element: (
      <ProtectedRoute>
        <AuthLayout />
      </ProtectedRoute>
    ),
    children: [
      { path: '/', exact: true, name: 'Home' },
      { path: '/dashboard', name: 'Dashboard', element: <AgentDashboard /> },
      { path: '/profile', name: 'Profile', element: <Profile /> },
      { path: '/edit-profile', name: 'Edit Profile', element: <EditProfile /> },
      { path: '/change-password', name: 'Change Password', element: <ChangePassword /> },
      { path: '/players', name: 'Players', element: <Players /> },
      { path: '/reports', name: 'Transaction Reports', element: <TransacGuarantorReports /> },
      { path: '/player-reports', name: 'VIP Reports', element: <VIPReports /> },
      { path: '/activitylogs', name: 'Transaction Reports', element: <ActivityLogs /> },
      {
        path: '/downline-per-event-commission',
        name: 'Events Reports',
        element: <DownlinePerEventReport />,
      },
      {
        path: '/operators-per-event-commission',
        name: 'Events Reports',
        element: <OperatorsPerEventReport />,
      },

      {
        path: '/player-records',
        name: 'Transaction Reports',
        element: <OperatorReportTabs />,
      },
      {
        path: '/pending-players',
        name: 'Pending Users',
        element: <PendingUsers />,
      },
      // {
      //   path: '/manage-commission',
      //   name: 'Manage Commission',
      //   element: <AgentCommissionManagement />,
      // },
      // { path: '/my-request-details', name: 'My Request Details', element: <MyRequestDetails /> },
      // { path: '/request', name: 'Request', element: <Request /> },
      // { path: '/my-request', name: 'My Request', element: <MyRequest /> },
      // { path: '/request-downline', name: 'My Request Downline', element: <RequestDownline /> },
      // { path: '/request-downline', name: 'My Request Downline', element: <RequestDownline /> },
      // { path: '/request-approval', name: 'My Request Approval', element: <RequestApproval /> },
    ],
  },
  {
    path: '*',
    element: <Navigate to="/dashboard" />,
  },
]

export const defaultRoutes = [
  {
    element: <MainLayout />,
    children: [
      {
        path: '/',
        element: <Login />,
      },
      { path: '/register-masteragent', name: 'Register', element: <Register /> },
      { path: '/register-player', name: 'Register', element: <RegisterPlayer /> },
      { path: '/register-agent', name: 'Register', element: <RegisterAgent /> },
    ],
  },
  {
    path: '*',
    element: <Navigate to="/" />,
  },
]
