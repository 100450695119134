import { useState, useEffect } from 'react'
import { LogLevel } from '@microsoft/signalr'
import * as signalR from '@microsoft/signalr'
import getUserProfile from 'src/api/userInfo/getUserProfile'
import { useAuth } from 'src/hooks/auth/useAuth'

const useSignalrPrivate = () => {
  const [signalWallet, setSignalWallet] = useState({})
  const [userProfile, setUserProfile] = useState({})
  const [userLogout, setUserLogout] = useState('')
  const [connectionState, setConnectionState] = useState('Disconnected')

  const handleGetUserProfile = async () => {
    try {
      const response = await getUserProfile()
      setUserProfile(response.data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    handleGetUserProfile()
  }, [])

  const { token } = useAuth()

  const newConnection = new signalR.HubConnectionBuilder()
    .withUrl(process.env.REACT_APP_SIGNAL_R_PRIVATE_URL, {
      accessTokenFactory: () => token?.accessToken,
    })
    .configureLogging(LogLevel.Information)
    .withAutomaticReconnect()
    .build()

  const handleSignalRResponse = (message) => {
    // handle the response here
    const [messageType, data] = message.split('|')

    if (messageType === 'WALLET_UPDATE') {
      const walletData = JSON.parse(data)
      setSignalWallet(walletData)
    }
    if (messageType === 'USER_LOGGEDOUT') {
      setUserLogout(data)
    }
  }

  const connectToSignalR = () => {
    newConnection
      .start()
      .then(() => {
        setConnectionState(newConnection.state)
        console.log('Connected private!')
        newConnection.send('RegisterToFeed', userProfile?.id)
      })
      .catch((e) => console.log('Connection failed: ', e))
  }

  const disconnectToSignalR = () => {
    newConnection
      .stop()
      .then(() => {
        setConnectionState(newConnection.state)

        console.log('Disconnected public!')
      })
      .catch((e) => console.log('Disconnection failed: ', e))
  }

  const handleVisibilityChange = () => {
    if (document.visibilityState === 'visible' && connectionState === 'Disconnected') {
      connectToSignalR()
    } else {
      disconnectToSignalR()
    }
  }

  newConnection.on('ReceivePrivateUpdate', (message) => {
    handleSignalRResponse(message)
  })

  newConnection.onclose(() => {
    console.log(newConnection.state)
    setConnectionState(newConnection.state)
  })

  useEffect(() => {
    if (newConnection.state === 'Disconnected') {
      connectToSignalR()
    }
  }, [userProfile?.id])

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange)
    return () => document.removeEventListener('visibilitychange', handleVisibilityChange)
  }, [])

  return { signalWallet, userLogout }
}

export default useSignalrPrivate
